import {
  showMessageBarAction,
  showErrorMessageBarAction,
  ModalName,
  showModalAction,
} from "./UIActions";
import {
  EntityType,
  updateCurrentListItemDispatcher,
  Operation,
  currentEntityAction,
} from "./CurrentActions";
import { deleteEntityService } from "../../service/DeleteEntityService";
import { removeFileUploadAction } from "./UploadQueueActions";
import { Logger } from "aws-amplify";
import { logError } from "../../service/ServiceUtil";

export const deleteCurrentEntityDispatcher = (
  entityType,
  entityId,
  callback
) => {
  return (dispatch, getState) => {
    deleteEntityService
      .deleteEntity(dispatch, getState, entityType, entityId)
      .then((entity) => {
        let currentIdState = getState().current.id;
        let parentEntityIdName = entityType + "Id";
        // Only delete currentList of currentEntityId is still the same as the parentId
        if (
          typeof currentIdState[parentEntityIdName] !== "undefined" &&
          currentIdState[parentEntityIdName] === entityId
        ) {
          dispatch(currentEntityAction(entityType, null));
        }
        dispatch(
          showMessageBarAction(
            true,
            `${entityType.replace(/([A-Z])/g, " $1").trim()} deleted`
          )
        );
        callback && callback(entity);
      })
      .catch((e) => {});
  };
};

export const deleteUserSessionDispatcher = (sessionKey) => {
  return async (dispatch, getState) => {
    try {
      await deleteEntityService.deleteEntity(
        dispatch,
        getState,
        EntityType.UserSession,
        sessionKey
      );
    } catch (e) {
      logError("deleteUserSessionDispatcher", { sessionKey }, e);
    }
  };
};

export const deleteAccountInviteDispatcher = (accountInviteId) => {
  return (dispatch, getState) => {
    deleteEntityService
      .deleteEntity(
        dispatch,
        getState,
        EntityType.AccountInvite,
        accountInviteId
      )
      .then((accountInvite) => {
        dispatch(
          updateCurrentListItemDispatcher(
            EntityType.AccountInvite,
            accountInvite,
            Operation.Delete
          )
        );
        dispatch(showErrorMessageBarAction(false));
        dispatch(showMessageBarAction(true, `Invite cancelled`));
      })
      .catch((e) => {});
  };
};

export const deletePlaylistDispatcher = (playlistId) => {
  return async (dispatch, getState) => {
    const playlist = await deleteEntityService.deleteEntity(
      dispatch,
      getState,
      EntityType.Playlist,
      playlistId
    );
    dispatch(
      updateCurrentListItemDispatcher(
        EntityType.Playlist,
        playlist,
        Operation.Delete
      )
    );
    dispatch(showErrorMessageBarAction(false));
    dispatch(showMessageBarAction(true, `${playlist.name} deleted`));
    return playlist;
  };
};

export const deletePlaylistContentDispatcher = (playlistContentId) => {
  return async (dispatch, getState) => {
    const playlistContent = await deleteEntityService.deleteEntity(
      dispatch,
      getState,
      EntityType.PlaylistContent,
      playlistContentId
    );
    dispatch(
      updateCurrentListItemDispatcher(
        EntityType.PlaylistContent,
        playlistContent,
        Operation.Delete
      )
    );
    dispatch(showErrorMessageBarAction(false));
    dispatch(
      showMessageBarAction(
        true,
        `${playlistContent.name} removed from Playlist`
      )
    );
    return playlistContent;
  };
};

export const deleteChannelDispatcher = (channelId) => {
  return async (dispatch, getState) => {
    const channel = await deleteEntityService.deleteEntity(
      dispatch,
      getState,
      EntityType.Channel,
      channelId
    );
    dispatch(
      updateCurrentListItemDispatcher(
        EntityType.Channel,
        channel,
        Operation.Delete
      )
    );
    dispatch(showErrorMessageBarAction(false));
    dispatch(showMessageBarAction(true, `${channel.name} deleted`));
    return channel;
  };
};

export const deleteChannelShareDispatcher = (channelShareId) => {
  return (dispatch, getState) => {
    deleteEntityService
      .deleteEntity(dispatch, getState, EntityType.ChannelShare, channelShareId)
      .then((channelShare) => {
        dispatch(
          updateCurrentListItemDispatcher(
            EntityType.ChannelShare,
            channelShare,
            Operation.Delete
          )
        );
        dispatch(showErrorMessageBarAction(false));
        dispatch(showMessageBarAction(true, `Share deleted`));
      })
      .catch((e) => {});
  };
};

export const deleteContentShareDispatcher = (contentShareId) => {
  return async (dispatch, getState) => {
    const contentShare = await deleteEntityService.deleteEntity(
      dispatch,
      getState,
      EntityType.ContentShare,
      contentShareId
    );
    dispatch(
      updateCurrentListItemDispatcher(
        EntityType.ContentShare,
        contentShare,
        Operation.Delete
      )
    );
    dispatch(showErrorMessageBarAction(false));
    dispatch(showMessageBarAction(true, `Share deleted`));
  };
};

export const deletePlaylistShareDispatcher = (playlistShareId) => {
  return async (dispatch, getState) => {
    const playlistShare = await deleteEntityService.deleteEntity(
      dispatch,
      getState,
      EntityType.PlaylistShare,
      playlistShareId
    );
    dispatch(
      updateCurrentListItemDispatcher(
        EntityType.PlaylistShare,
        playlistShare,
        Operation.Delete
      )
    );
    dispatch(showErrorMessageBarAction(false));
    dispatch(showMessageBarAction(true, `Share deleted`));
  };
};

export const deleteContentDispatcher = (contentId) => {
  return async (dispatch, getState) => {
    const content = await deleteEntityService.deleteEntity(
      dispatch,
      getState,
      EntityType.Content,
      contentId
    );
    dispatch(
      updateCurrentListItemDispatcher(
        EntityType.Content,
        content,
        Operation.Delete
      )
    );
    dispatch(showErrorMessageBarAction(false));
    dispatch(showMessageBarAction(true, `${content.name} deleted`));
  };
};

export const deleteUploadDispatcher = (uploadId) => {
  return async (dispatch, getState) => {
    const upload = await deleteEntityService.deleteEntity(
      dispatch,
      getState,
      EntityType.Upload,
      uploadId
    );
    dispatch(removeFileUploadAction(upload));
    dispatch(
      updateCurrentListItemDispatcher(
        EntityType.Upload,
        upload,
        Operation.Delete
      )
    );
    dispatch(deleteContentDispatcher(upload.contentId));
    dispatch(showErrorMessageBarAction(false));
    if (getState().uploadQueue.fileUploadArray.length === 0)
      dispatch(showModalAction(ModalName.FileUploadModal, false));
  };
};

export const deleteBotSessionDispatcher = (botSessionId) => {
  return async (dispatch, getState) => {
    const botSession = await deleteEntityService.deleteEntity(
      dispatch,
      getState,
      EntityType.BotSession,
      botSessionId,
      { applyToSeries: true }
    );
    dispatch(
      updateCurrentListItemDispatcher(
        EntityType.BotSession,
        botSession,
        Operation.Delete
      )
    );
    dispatch(showErrorMessageBarAction(false));
    dispatch(showMessageBarAction(true, `Meeting series removed`));
    return botSession;
  };
};

export const deletePostDispatcher = (entityTypeName, postId) => {
  return async (dispatch, getState) => {
    const post = await deleteEntityService.deleteEntity(
      dispatch,
      getState,
      "post",
      postId
    );
    dispatch(
      updateCurrentListItemDispatcher(entityTypeName, post, Operation.Delete)
    );
    dispatch(showErrorMessageBarAction(false));
    dispatch(showMessageBarAction(true, `Post deleted`));
    return post;
  };
};

export const deleteCommentDispatcher = (commentId) => {
  return async (dispatch, getState) => {
    const comment = await deleteEntityService.deleteEntity(
      dispatch,
      getState,
      EntityType.Comment,
      commentId
    );
    //Delete parent comments in place:
    if (comment.id === commentId)
      dispatch(
        updateCurrentListItemDispatcher(
          EntityType.Comment,
          comment,
          Operation.Delete
        )
      );
    //Delete a child comment by updating parent:
    else
      dispatch(
        updateCurrentListItemDispatcher(
          EntityType.Comment,
          comment,
          Operation.Update
        )
      );
    dispatch(showErrorMessageBarAction(false));
    dispatch(showMessageBarAction(true, `Comment deleted`));
    return comment;
  };
};

export const deleteChannelSubscriptionDispatcher = (channelId) => {
  return async (dispatch, getState) => {
    const channelSubscription = await deleteEntityService.deleteEntity(
      dispatch,
      getState,
      EntityType.ChannelSubscription,
      channelId
    );
    dispatch(showErrorMessageBarAction(false));
    dispatch(
      showErrorMessageBarAction(
        true,
        `You have unsubscribed from ${channelSubscription.channel.name}`
      )
    );
  };
};

export const deletePlaylistSubscriptionDispatcher = (playlistId) => {
  return async (dispatch, getState) => {
    const playlistSubscription = await deleteEntityService.deleteEntity(
      dispatch,
      getState,
      EntityType.PlaylistSubscription,
      playlistId
    );
    dispatch(showErrorMessageBarAction(false));
    dispatch(
      showErrorMessageBarAction(
        true,
        `You have unsubscribed from ${playlistSubscription.playlist.name}`
      )
    );
  };
};

export const deleteAccountUserFollowerDispatcher = (
  userProfileId,
  accountUserId = null
) => {
  return async (dispatch, getState) => {
    const queryObj = {
      accountUserId: accountUserId
        ? accountUserId
        : getState().current.entity.userSession.accountUser.id,
    };
    const accountUserFollower = await deleteEntityService.deleteEntity(
      dispatch,
      getState,
      EntityType.AccountUserFollower,
      userProfileId,
      queryObj
    );
    dispatch(
      updateCurrentListItemDispatcher(
        EntityType.AccountUserFollower,
        accountUserFollower,
        Operation.Delete
      )
    );
    dispatch(showErrorMessageBarAction(false));
    dispatch(
      showErrorMessageBarAction(
        true,
        `You have unfollowed ${accountUserFollower.fullName}`
      )
    );
  };
};
