/*global chrome*/

import React, { useEffect, useState, useRef, useMemo, use } from "react";
import { isWWW, push, showIntercomArticle } from "../../util/Utils";
import { connect } from "react-redux";
import {
  doneRecordingDispatcher,
  startRecordingCountdownDispatcher,
  stopRecordingDispatcher,
  setRecordedFileAction,
  RecorderOptions,
  setRecorderOptionAction,
  setScreenSizeAction,
  closeRecorderDispatcher,
} from "../../redux/action/RecorderActions";
import { showErrorMessageBarAction } from "../../redux/action/UIActions";
import {
  currentEntityAction,
  EntityType,
} from "../../redux/action/CurrentActions";
import { makeStyles } from "tss-react/mui";
import {
  createUploadDispatcher,
  trackEvent,
} from "../../redux/action/CreateEntityActions";
import {
  finishMultipartUploadSessionDispatcher,
  queueChunkForUploadDispatcher,
  startMultipartUploadSession,
} from "../../redux/action/UploadSessionActions";
import FormModal from "../../form/FormModal";
import Loader from "../loader/Loader";
import { logError, logInfo, logWarning } from "../../service/ServiceUtil";

const MIME_TYPE = "video/webm";
const mediaRecorderOptions = {
  mimeType: `${MIME_TYPE};codecs=h264,opus`,
  audioBitsPerSecond: 128000,
  videoBitsPerSecond: 2500000,
};
const RECORDER_TIMESLICE = 2000;

export const RecorderMethod = {
  doInit: "doInit",
  startRecording: "startRecording",
  stopRecording: "stopRecording",
  cancelRecording: "cancelRecording",
};

export const RecorderStatus = {
  none: "none",
  done: "done",
  init: "init",
  recording: "recording",
  ready: "ready",
  error: "error",
};

const getCanvasImageAsBlobUrl = async (videoCanvas) => {
  const blob = await new Promise((res) => videoCanvas.toBlob(res));
  return URL.createObjectURL(blob);
};

const useStyles = makeStyles()(() => ({
  hiddenComponent: {
    position: "absolute",
    top: "0",
    display: "none",
  },
}));

const Recorder = ({
  recorderMethod,
  initStatus,
  readyStatus,
  doneStatus,
  errorStatus,
  startRecordingStatus,
  setRecordedFile,
  pipDimensions,
  screenSize,
  setScreenSizeAction,
  closeRecorder,
  queueChunkForUpload,
  createUpload,
  setPipOffset,
}) => {
  const recorderWorkerRef = useRef(null);
  const activeTracksStreamRef = useRef(null);
  const audioContextRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const recorderCanvasRef = useRef();
  const cameraVideoRef = useRef();
  const cameraCanvasRef = useRef();
  const screenVideoRef = useRef();
  const posterBlobUrlRef = useRef();
  const recorderCanvasContextRef = useRef();
  const cameraCanvasContextRef = useRef();
  const isRecordingCancelled = useRef(false);
  const cameraDimensionsRef = useRef({ width: 0, height: 0 });
  const pipDimensionsRef = useRef({ x: 0, y: 0, width: 1280, height: 720 });
  const [isSavingVideo, setIsSavingVideo] = useState(false);

  const reset = () => {
    activeTracksStreamRef.current = new MediaStream();
    mediaRecorderRef.current = null;
    isRecordingCancelled.current = false;
    posterBlobUrlRef.current = null;
    if (audioContextRef.current) {
      audioContextRef.current.close();
      audioContextRef.current = null;
    }
    setIsSavingVideo(false);
  };

  const init = async (recorderOptions) => {
    logInfo("Recorder initRecorder");
    try {
      initStatus();
      reset();
      await initScreenRecorder(recorderOptions);
      readyStatus();
    } catch (e) {
      errorStatus(e.message);
    }
  };

  const initScreenRecorder = async (recorderOptions) => {
    const {
      recordDesktop,
      recordAudio,
      muteMic,
      micId,
      muteCamera,
      cameraId,
      cameraRenderCanvasId,
    } = recorderOptions;
    try {
      recorderCanvasContextRef.current = recorderCanvasRef.current.getContext(
        "2d",
        {
          alpha: false,
        }
      );
      const captureStream = recorderCanvasRef.current.captureStream();
      activeTracksStreamRef.current.addTrack(captureStream.getTracks()[0]);
      try {
        if ((!muteMic && micId) || (!muteCamera && cameraId)) {
          const cameraMedia = await navigator.mediaDevices.getUserMedia({
            audio: !muteMic && micId ? { deviceId: { exact: micId } } : false,
            video:
              !muteCamera && cameraId
                ? { deviceId: { exact: cameraId } }
                : false,
          });
          logInfo("initScreenRecorder", 1);
          cameraMedia
            .getTracks()
            .forEach((track) => activeTracksStreamRef.current.addTrack(track));
          logInfo("initScreenRecorder", 2);
          cameraVideoRef.current =
            document.getElementById(cameraRenderCanvasId);
          // cameraVideoRef.current = window.cameraCanvas;

          cameraCanvasContextRef.current = cameraCanvasRef.current.getContext(
            "2d",
            { alpha: true }
          );
          logInfo("initScreenRecorder", 3);
          if (cameraMedia.getVideoTracks().length > 0) {
            // const settings = cameraMedia.getVideoTracks()[0].getSettings();
            cameraDimensionsRef.current = {
              width: cameraVideoRef.current.width,
              height: cameraVideoRef.current.height,
            };
          }
          logInfo("initScreenRecorder", 4);
          cameraMedia
            .getTracks()[0]
            .addEventListener("ended", trackEndedHandler);
          if (cameraMedia.getAudioTracks().length > 0)
            captureStream.addTrack(cameraMedia.getAudioTracks()[0]);
          logInfo("initScreenRecorder", 11);
        }
        logInfo("initScreenRecorder", 5);

        if (recordDesktop) {
          const screenMedia = await navigator.mediaDevices.getDisplayMedia({
            audio: recordAudio,
            video: {
              cursor: "motion",
            },
          });
          if (screenMedia.getAudioTracks().length > 0) {
            const captureAudioTracks = captureStream.getAudioTracks();
            captureAudioTracks.forEach((track) =>
              captureStream.removeTrack(track)
            );
            captureStream.addTrack(
              mixAudioTracksIntoStream([
                ...captureAudioTracks,
                ...screenMedia.getAudioTracks(),
              ]).getAudioTracks()[0]
            );
          }
          screenMedia
            .getTracks()
            .forEach((track) => activeTracksStreamRef.current.addTrack(track));
          const screenMediaSettings = screenMedia
            .getVideoTracks()[0]
            .getSettings();
          logInfo("initScreenRecorder", 7, screenMediaSettings);

          screenMedia
            .getTracks()[0]
            .addEventListener("ended", trackEndedHandler);
          logInfo("initScreenRecorder", 8);

          logInfo(
            "Screen Tracks to add to screen stream",
            screenMedia.getTracks()
          );

          const screenVideoLoadedHandler = (e) => {
            //Video will always be an even number so divide by 8 to be safe:
            const isRecordingTab =
              Math.trunc(screenVideoRef.current.videoWidth / 8) !==
                Math.trunc(window.screen.width / 8) ||
              Math.trunc(screenVideoRef.current.videoHeight / 8) !==
                Math.trunc(window.screen.height / 8);
            //If recording tab, switch pip coordinates to screen instead of window:
            if (isRecordingTab) {
              setPipOffset(0);
              setScreenSizeAction(
                screenVideoRef.current.videoWidth / window.devicePixelRatio,
                screenVideoRef.current.videoHeight / window.devicePixelRatio
              );
            } else {
              setScreenSizeAction(
                screenVideoRef.current.videoWidth,
                screenVideoRef.current.videoHeight
              );
            }
            screenVideoRef.current.removeEventListener(
              "loadedmetadata",
              screenVideoLoadedHandler
            );
          };
          screenVideoRef.current.addEventListener(
            "loadedmetadata",
            screenVideoLoadedHandler,
            { once: true }
          );
          screenVideoRef.current.srcObject = new MediaStream(
            screenMedia.getVideoTracks()
          );
          logInfo("initScreenRecorder", 9);
        }

        mediaRecorderRef.current = new MediaRecorder(
          captureStream,
          mediaRecorderOptions
        );
        logInfo("initScreenRecorder", 12);

        let screenRecordedStreamArray = [];
        let chunkSequenceNumber = 0;
        const mediaRecorderDataAvailableHandler = (e) => {
          if (e.data.size > 0) {
            screenRecordedStreamArray.push(e.data);
            const chunkBlob = new Blob([e.data]);
            queueChunkForUpload(
              recorderOptions.uploadSessionId,
              chunkSequenceNumber++,
              chunkBlob
            );
          }
        };
        mediaRecorderRef.current.addEventListener(
          "dataavailable",
          mediaRecorderDataAvailableHandler
        );
        const mediaRecorderStopEventHandler = async (e) => {
          try {
            activeTracksStreamRef.current.getTracks().forEach((track) => {
              track.removeEventListener("ended", trackEndedHandler);
              track.stop();
            });
            captureStream.getTracks().forEach((track) => track.stop());
            doneStatus();
            const videoBlob = new Blob(screenRecordedStreamArray);
            if (!isRecordingCancelled.current) {
              closeRecorder();
              const filename = `${
                recordDesktop && !muteCamera
                  ? "Webcam and Screen"
                  : recordDesktop
                  ? "Screen"
                  : muteCamera
                  ? "Mic"
                  : "Webcam"
              }.webm`;
              let file = new File([videoBlob], filename, {
                type: MIME_TYPE,
              });
              file.url = URL.createObjectURL(videoBlob);
              file.recorderOptions = recorderOptions;
              setRecordedFile(file);
              await createUpload(file, posterBlobUrlRef.current);
              trackEvent("recordingComplete");
            }
          } catch (error) {
            logError("mediaRecorderStopEventHandler", e, error);
          }
          reset();
        };
        mediaRecorderRef.current.addEventListener(
          "stop",
          mediaRecorderStopEventHandler,
          { once: true }
        );
      } catch (error) {
        if (error.message === "Permission denied by system")
          showIntercomArticle(5269902);
        else showIntercomArticle(5269961);
        logWarning("Permission denied", recorderOptions, error);
        throw error;
      }
      const mediaRecorderStartEventHandler = async (e) => {
        logInfo("mediaRecorderRef.current.start");
        recorderOptions.uploadSessionId = await startRecordingStatus("webm");
      };
      mediaRecorderRef.current.addEventListener(
        "start",
        mediaRecorderStartEventHandler,
        { once: true }
      );
      renderCanvas(recorderOptions);
      logInfo("initScreenRecorder", 12);
    } catch (e) {
      logError("Error obtaining screen stream: ", recorderOptions, e);
      activeTracksStreamRef.current.getTracks().forEach((track) => {
        track.stop();
      });
      reset();
      closeRecorder();
      throw e;
    }
  };

  const mixAudioTracksIntoStream = (audioTracks) => {
    audioContextRef.current = new AudioContext();
    const destination = audioContextRef.current.createMediaStreamDestination();

    audioTracks.forEach((audioTrack) => {
      const source = audioContextRef.current.createMediaStreamSource(
        new MediaStream([audioTrack])
      );
      const gainNode = audioContextRef.current.createGain();
      source.connect(gainNode).connect(destination);
    });
    return destination.stream;
  };

  const drawBackgroundFrame = async (recorderOptions) => {
    const recorderCanvasContext = recorderCanvasContextRef.current;
    if (recorderOptions.recordDesktop) {
      recorderCanvasContext.drawImage(
        screenVideoRef.current,
        0,
        0,
        recorderCanvasRef.current.width,
        recorderCanvasRef.current.height
      );
    } else {
      const aspectRatio =
        recorderCanvasRef.current.width / recorderCanvasRef.current.height;
      const cameraVideo = cameraVideoRef.current;
      const cameraWidth = cameraVideo.width;
      const cameraHeight = cameraVideo.height;
      const tooWide = cameraWidth / cameraHeight > aspectRatio;
      const cameraCropWidth = tooWide
        ? cameraHeight * aspectRatio
        : cameraWidth;
      const cameraCropHeight = tooWide
        ? cameraHeight
        : cameraWidth / aspectRatio;
      const cameraX = (cameraWidth - cameraCropWidth) / 2;
      const cameraY = (cameraHeight - cameraCropHeight) / 2;
      recorderCanvasContext.drawImage(
        cameraVideo,
        cameraX,
        cameraY,
        cameraCropWidth,
        cameraCropHeight,
        0,
        0,
        recorderCanvasRef.current.width,
        recorderCanvasRef.current.height
      );
    }
  };

  // Check when it is recordDesktop and if the track width/height differs from window.screen.width/height,
  // then the user is recording a tab and not the screen. When this is the case, subtract window.screenX/Y from pipDimensionsRef
  // so the pip aligns on the canvas properly (drawBubbleFrame).

  const drawBubbleFrame = async (recorderOptions) => {
    if (
      recorderOptions.recordDesktop &&
      !recorderOptions.muteCamera &&
      recorderOptions.cameraId
    ) {
      await drawBubbleCanvas();
      const recorderCanvasContext = recorderCanvasContextRef.current;
      recorderCanvasContext.fillStyle = "white";
      recorderCanvasContext.beginPath();
      const radius = pipDimensionsRef.current.width / 2 + 2;
      recorderCanvasContext.arc(
        pipDimensionsRef.current.x + pipDimensionsRef.current.width / 2,
        pipDimensionsRef.current.y + pipDimensionsRef.current.height / 2,
        radius,
        0,
        2 * Math.PI
      );
      recorderCanvasContext.fill();
      recorderCanvasContext.drawImage(
        cameraCanvasRef.current,
        pipDimensionsRef.current.x,
        pipDimensionsRef.current.y,
        pipDimensionsRef.current.width,
        pipDimensionsRef.current.height
      );
    }
  };

  const drawBubbleCanvas = async () => {
    const cameraCanvasContext = cameraCanvasContextRef.current;
    const cameraDimensions = cameraDimensionsRef.current;
    const cameraCanvasDimensions = {
      width: cameraCanvasRef.current.width,
      height: cameraCanvasRef.current.height,
    };
    const cameraAspectRatio = cameraDimensions.width / cameraDimensions.height;
    const canvasAspectRatio =
      cameraCanvasDimensions.width / cameraCanvasDimensions.height;
    let sX = 0,
      sY = 0,
      sW = cameraDimensions.width,
      sH = cameraDimensions.height;
    if (cameraAspectRatio > canvasAspectRatio) {
      //Too wide:
      const newWidth = cameraDimensions.height * canvasAspectRatio;
      sX = (cameraDimensions.width - newWidth) / 2;
      sW = newWidth;
    } else {
      //Too tall:
      const newHeight = cameraDimensions.width / canvasAspectRatio;
      sY = (cameraDimensions.height - newHeight) / 2;
      sH = newHeight;
    }
    //Clipping path:
    cameraCanvasContext.clearRect(
      0,
      0,
      cameraCanvasDimensions.width,
      cameraCanvasDimensions.height
    );
    const radius = cameraCanvasDimensions.width / 2;
    cameraCanvasContext.beginPath();
    cameraCanvasContext.arc(radius, radius, radius, 0, 2 * Math.PI);
    cameraCanvasContext.clip();

    cameraCanvasContext.drawImage(
      cameraVideoRef.current,
      sX,
      sY,
      sW,
      sH,
      0,
      0,
      cameraCanvasDimensions.width,
      cameraCanvasDimensions.height
    );
  };

  const renderCanvas = (recorderOptions) => {
    const step = async () => {
      try {
        await drawBackgroundFrame(recorderOptions);
        await drawBubbleFrame(recorderOptions);
        if (!posterBlobUrlRef.current)
          posterBlobUrlRef.current = await getCanvasImageAsBlobUrl(
            recorderCanvasRef.current
          );
      } catch (e) {
        logError("renderCanvas()", recorderOptions, e);
      }
      if (mediaRecorderRef.current)
        recorderWorkerRef.current.postMessage({
          messageType: "requestAnimationFrame",
          timeout: 30,
          recorderOptions,
          requester: "Recorder",
        });
    };
    step();
  };

  const startRecording = () => {
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state !== "recording"
    ) {
      mediaRecorderRef.current.start(RECORDER_TIMESLICE);
    } else logError("Media Recorder is not initialized");
  };

  const trackEndedHandler = (e) => {
    // Note: I don't believe this method fires then track.stop() is called (referenced needed)
    // but it does fire when the user clicks to stop sharing the screen/camera in the browser.
    stopRecording();
  };

  const stopRecording = () => {
    logInfo(
      "stopRecording activeTracksStreamRef",
      activeTracksStreamRef.current
        ? activeTracksStreamRef.current.getTracks()
        : "none"
    );
    setIsSavingVideo(!isRecordingCancelled.current);
    setTimeout(() => {
      if (mediaRecorderRef.current?.state === "recording")
        mediaRecorderRef.current.stop();
      else {
        activeTracksStreamRef.current &&
          activeTracksStreamRef.current.getTracks().forEach((track) => {
            track.removeEventListener("ended", trackEndedHandler);
            track.stop();
          });
        reset();
        doneStatus();
      }
    }, 1000);
  };

  useEffect(() => {
    if (recorderMethod.method) {
      switch (recorderMethod.method) {
        case RecorderMethod.doInit:
          init(recorderMethod.recorderOptions);
          break;
        case RecorderMethod.startRecording:
          startRecording();
          break;
        case RecorderMethod.stopRecording:
          stopRecording();
          break;
        case RecorderMethod.cancelRecording:
          isRecordingCancelled.current = true;
          stopRecording();
          break;
        default:
      }
    }
  }, [recorderMethod.method]);

  const recorderWorkerEventListener = (event) => {
    const {
      messageType = "",
      recorderOptions = {},
      requester,
    } = event.data ?? {};
    switch (messageType) {
      case "recorderAnimationStep":
        if (requester === "Recorder") renderCanvas(recorderOptions);
        break;
    }
  };

  useEffect(() => {
    if (typeof Worker !== "undefined" && !isWWW) {
      recorderWorkerRef.current = new Worker("/static/js/RecorderWorker.js");
      recorderWorkerRef.current.addEventListener(
        "message",
        recorderWorkerEventListener
      );
      return () =>
        recorderWorkerRef.current.removeEventListener(
          "message",
          recorderWorkerEventListener
        );
    }
  }, []);

  useEffect(() => {
    pipDimensionsRef.current = pipDimensions;
  }, [pipDimensions]);

  const { classes } = useStyles();

  return (
    <>
      {isSavingVideo && (
        <FormModal
          title="Saving Video"
          open={true}
          hideOnBlur={false}
          form={<Loader />}
        />
      )}
      <video
        id="screen-video"
        ref={screenVideoRef}
        controls
        autoPlay="autoplay"
        width={screenSize.width}
        height={screenSize.height}
        className={classes.hiddenComponent}
        style={{
          ...screenSize,
        }}
      />
      <canvas
        id="camera-canvas"
        ref={cameraCanvasRef}
        width={pipDimensions.width}
        height={pipDimensions.height}
        className={classes.hiddenComponent}
        style={{
          width: pipDimensions.width,
          height: pipDimensions.height,
        }}
      ></canvas>
      <canvas
        id="recorder-canvas"
        ref={recorderCanvasRef}
        width={screenSize.width}
        height={screenSize.height}
        className={classes.hiddenComponent}
        style={{
          ...screenSize,
        }}
      ></canvas>
    </>
  );
};

const mapStateToProps = (state) => {
  const {
    recorderMethod,
    pipDimensions,
    screenSize = { width: 1280, height: 720 },
  } = state.recorder;
  return {
    recorderMethod,
    pipDimensions,
    screenSize,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    initStatus: () => {
      dispatch(
        setRecorderOptionAction(
          RecorderOptions.recorderStatus,
          RecorderStatus.init
        )
      );
    },
    readyStatus: () => {
      dispatch(
        setRecorderOptionAction(
          RecorderOptions.recorderStatus,
          RecorderStatus.ready
        )
      );
      dispatch(startRecordingCountdownDispatcher());
    },
    doneStatus: () => {
      dispatch(
        setRecorderOptionAction(
          RecorderOptions.recorderStatus,
          RecorderStatus.done
        )
      );
      dispatch(doneRecordingDispatcher());
    },
    closeRecorder: () => {
      dispatch(closeRecorderDispatcher());
    },
    stopRecordingStatus: () => {
      dispatch(
        setRecorderOptionAction(
          RecorderOptions.recorderStatus,
          RecorderStatus.none
        )
      );
      dispatch(stopRecordingDispatcher());
    },
    startRecordingStatus: async (fileExtension) => {
      dispatch(
        setRecorderOptionAction(
          RecorderOptions.recorderStatus,
          RecorderStatus.recording
        )
      );
      dispatch(setRecorderOptionAction(RecorderOptions.startTime, new Date()));
      const uploadSessionId = await dispatch(
        startMultipartUploadSession(fileExtension)
      );
      dispatch(
        setRecorderOptionAction(
          RecorderOptions.uploadSessionId,
          uploadSessionId
        )
      );
      return uploadSessionId;
    },
    errorStatus: (message) => {
      dispatch(
        setRecorderOptionAction(
          RecorderOptions.recorderStatus,
          RecorderStatus.error
        )
      );
      message && dispatch(showErrorMessageBarAction(true, message));
      dispatch(stopRecordingDispatcher());
    },
    setPipOffset: (value) => {
      dispatch(setRecorderOptionAction(RecorderOptions.pipOffset, value));
    },
    setRecordedFile: (file) => {
      dispatch(currentEntityAction(EntityType.Upload, null));
      dispatch(setRecordedFileAction(file));
    },
    setScreenSizeAction: (width, height) =>
      dispatch(setScreenSizeAction({ width, height })),
    queueChunkForUpload: (sessionId, sequenceNumber, chunkFile) =>
      dispatch(
        queueChunkForUploadDispatcher(sessionId, sequenceNumber, chunkFile)
      ),
    createUpload: async (file, posterUrl) => {
      try {
        await dispatch(
          finishMultipartUploadSessionDispatcher(
            file.recorderOptions.uploadSessionId
          )
        );
        const upload = await dispatch(
          createUploadDispatcher(file, {
            isPrivate: true,
            posterUrl,
            channelId: ownProps.channelId,
          })
        );
        dispatch(
          setRecorderOptionAction(RecorderOptions.contentId, upload.contentId)
        );
        if (window.location.pathname.startsWith("/chat")) {
          push(window.location.pathname, { contentId: upload.contentId });
        } else push(`/player/${upload.contentId}`);
      } catch (e) {
        dispatch(showErrorMessageBarAction(true, e.userMessage || e.message));
      }
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Recorder);
