import { updateEntityService } from "../../service/UpdateEntityService";
import {
  currentEntityAction,
  updateCurrentListItemDispatcher,
  Operation,
  EntityType,
} from "./CurrentActions";
import { updateFileUploadContentAction } from "./UploadQueueActions";
import {
  showMessageBarAction,
  removeToasterMessageAction,
  showErrorMessageBarAction,
  ModalName,
  showModalAction,
  showCreateLoginModalDispatcher,
} from "./UIActions";
import { formatSecondsToTime, trackIntercomEvent } from "../../util/Utils";
import { trackEvent } from "./CreateEntityActions";
import { capitalCase } from "change-case";
import { ListName } from "../reducer/CurrentListActionReducer";

export const updateCurrentEntityDispatcher = (
  entityTypeName,
  entityId,
  formObject,
  action
) => {
  return async (dispatch, getState) => {
    const entity = await updateEntityService.updateEntity(
      dispatch,
      getState,
      entityTypeName,
      entityId,
      formObject,
      action
    );
    const currentIdState = getState().current.id;
    const entityIdName = entityTypeName + "Id";
    // Only update currentEntity if entityId is still the same as the id:
    if (
      typeof currentIdState[entityIdName] !== "undefined" &&
      currentIdState[entityIdName] === entityId
    ) {
      dispatch(currentEntityAction(entityTypeName, entity));
    }
    dispatch(
      updateCurrentListItemDispatcher(entityTypeName, entity, Operation.Update)
    );
    dispatch(
      showMessageBarAction(true, `${capitalCase(entityTypeName)} updated`)
    );
    return entity;
  };
};

export const updateAccountTypeAction = (accountId, accountType) => {
  return async (dispatch, getState) => {
    await updateEntityService.updateEntity(
      dispatch,
      getState,
      EntityType.Account,
      accountId,
      { accountType },
      "updateAccountType"
    );
    dispatch(showMessageBarAction(true, "Account upgraded!"));
  };
};

export const updateUserShowIntroStepsAction = (userlId) => {
  return async (dispatch, getState) => {
    return dispatch(
      updateCurrentEntityDispatcher(
        EntityType.User,
        userlId,
        { showIntroSteps: false },
        null
      )
    );
  };
};

export const updatePasswordAction = (currentPassword, newPassword) => {
  const formObject = { currentPassword, newPassword };
  return async (dispatch, getState) => {
    await updateEntityService.updateEntity(
      dispatch,
      getState,
      EntityType.User,
      null,
      formObject,
      "updatePassword"
    );
    dispatch(showMessageBarAction(true, "Password updated"));
  };
};

export const updatePosterDispatcher = (contentId, playtime, callback) => {
  const formObject = { playtime };
  return (dispatch, getState) => {
    updateEntityService
      .updateEntity(
        dispatch,
        getState,
        EntityType.Content,
        contentId,
        formObject,
        "updatePosterTime"
      )
      .then((user) => {
        dispatch(
          showMessageBarAction(
            true,
            `Setting thumbnail to ${formatSecondsToTime(playtime)}`
          )
        );
        callback && callback(user);
      })
      .catch((e) => {});
  };
};

export const updateContentAction = (contentId, formObject) => {
  return async (dispatch, getState) => {
    const content = await updateEntityService.updateEntity(
      dispatch,
      getState,
      EntityType.Content,
      contentId,
      formObject
    );
    dispatch(updateFileUploadContentAction(content));
    if (content.contentStatus !== "DELETED") {
      dispatch(currentEntityAction(EntityType.Content, content));
      dispatch(
        updateCurrentListItemDispatcher(
          EntityType.Content,
          content,
          Operation.Update
        )
      );
    }
    dispatch(showMessageBarAction(true, `${content.name} updated`));
    return content;
  };
};

export const trimContentAction = (contentId, startTime, endTime) => {
  return async (dispatch, getState) => {
    const content = await updateEntityService.updateEntity(
      dispatch,
      getState,
      EntityType.Content,
      contentId,
      { startTime: startTime.toFixed(3), endTime: endTime.toFixed(3) },
      "trim"
    );
    if (content.contentStatus !== "DELETED") {
      await dispatch(currentEntityAction(EntityType.Content, content));
      await dispatch(
        updateCurrentListItemDispatcher(
          EntityType.Content,
          content,
          Operation.Update
        )
      );
    }
    dispatch(showMessageBarAction(true, `${content.name} trimmed`));
    return content;
  };
};

export const updateContentShareDispatcher = (contentShareId, formObject) => {
  return async (dispatch, getState) => {
    const contentShare = await updateEntityService.updateEntity(
      dispatch,
      getState,
      EntityType.ContentShare,
      contentShareId,
      formObject
    );
    dispatch(showErrorMessageBarAction(false));
    dispatch(currentEntityAction(EntityType.ContentShare, contentShare));
    dispatch(showMessageBarAction(true, "Share updated"));
  };
};

export const updateChannelShareDispatcher = (channelShareId, formObject) => {
  return async (dispatch, getState) => {
    const channelShare = await updateEntityService.updateEntity(
      dispatch,
      getState,
      EntityType.ChannelShare,
      channelShareId,
      formObject
    );
    dispatch(showErrorMessageBarAction(false));
    dispatch(currentEntityAction(EntityType.ChannelShare, channelShare));
    dispatch(showMessageBarAction(true, "Share updated"));
  };
};

export const updatePlaylistShareDispatcher = (playlistShareId, formObject) => {
  return async (dispatch, getState) => {
    const playlistShare = await updateEntityService.updateEntity(
      dispatch,
      getState,
      EntityType.PlaylistShare,
      playlistShareId,
      formObject
    );
    dispatch(showErrorMessageBarAction(false));
    dispatch(currentEntityAction(EntityType.PlaylistShare, playlistShare));
    dispatch(showMessageBarAction(true, "Share updated"));
  };
};

/** For account access requests accepted via email link: */
export const acceptAccessRequestDispatcher = (accessRequestCode) => {
  return async (dispatch, getState) => {
    const accessRequest = await updateEntityService.updateEntity(
      dispatch,
      getState,
      EntityType.AccessRequest,
      accessRequestCode,
      {},
      "accept"
    );
    dispatch(currentEntityAction(EntityType.AccessRequest, accessRequest));
  };
};

/** For content/channel share access requests accepted via email link: */
export const acceptShareRequestDispatcher = (entityType, requestCode) => {
  return async (dispatch, getState) => {
    const shareRequest = await updateEntityService.updateEntity(
      dispatch,
      getState,
      entityType + "Request",
      requestCode,
      {},
      "accept"
    );
    dispatch(currentEntityAction(EntityType.ShareRequest, shareRequest));
  };
};

/** For channel share access requests accepted via admin form: */
export const acceptRequestedChannelShareDispatcher = (channelShareId) => {
  return (dispatch, getState) => {
    updateEntityService
      .updateEntity(
        dispatch,
        getState,
        EntityType.ChannelShare,
        channelShareId,
        {},
        "accept"
      )
      .then((channelShare) => {
        dispatch(currentEntityAction(EntityType.ChannelShare, channelShare));
      })
      .catch((e) => {});
  };
};

/** For content share access requests accepted via admin form: */
export const acceptRequestedContentShareDispatcher = (contentShareId) => {
  return (dispatch, getState) => {
    updateEntityService
      .updateEntity(
        dispatch,
        getState,
        EntityType.ContentShare,
        contentShareId,
        {},
        "accept"
      )
      .then((contentShare) => {
        dispatch(currentEntityAction(EntityType.ContentShare, contentShare));
      })
      .catch((e) => {});
  };
};

export const markChatConversationReadDispatcher = (chatConversationId) => {
  return (dispatch, getState) => {
    updateEntityService
      .updateEntity(
        dispatch,
        getState,
        EntityType.ChatConversation,
        chatConversationId,
        null,
        "markRead"
      )
      .then((chatMessageList) => {
        for (var i = 0; i < chatMessageList.length; i++) {
          dispatch(
            updateCurrentListItemDispatcher(
              EntityType.ChatMessage,
              chatMessageList[i],
              Operation.Update
            )
          );
        }
      })
      .catch((e) => {});
  };
};

export const markParentNotificationMessagesReadDispatcher = (
  notificationMessageId
) => {
  return (dispatch, getState) => {
    dispatch(removeToasterMessageAction(notificationMessageId));
    updateEntityService
      .updateEntity(
        dispatch,
        getState,
        EntityType.NotificationMessage,
        notificationMessageId,
        null,
        "markParentNotificationsRead"
      )
      .then((notificationMessageList) => {
        for (var i = 0; i < notificationMessageList.length; i++) {
          dispatch(
            updateCurrentListItemDispatcher(
              EntityType.NotificationMessage,
              notificationMessageList[i],
              Operation.Update
            )
          );
        }
      })
      .catch((e) => {});
  };
};

export const markAllNotificationsReadDispatcher = (
  notificationMessageType = "all"
) => {
  return (dispatch, getState) => {
    updateEntityService
      .updateEntity(
        dispatch,
        getState,
        null,
        null,
        { notificationMessageType },
        "markAllNotificationsRead"
      )
      .then((notificationMessageList) => {
        for (var i = 0; i < notificationMessageList.length; i++) {
          dispatch(
            updateCurrentListItemDispatcher(
              EntityType.NotificationMessage,
              notificationMessageList[i],
              Operation.Update
            )
          );
        }
      })
      .catch((e) => {});
  };
};

export const importRecordingsDispatcher = ({
  entityType,
  recordingIdList,
  channelId,
  channelName,
  isPrivate,
}) => {
  return async (dispatch, getState) => {
    const recordingList = await updateEntityService.updateEntity(
      dispatch,
      getState,
      entityType,
      null,
      { channelId, channelName, recordingIdList, isPrivate },
      "importList"
    );
    if (recordingList.length === 0) throw new Error("No videos imported.");
    for (var i = 0; i < recordingList.length; i++) {
      dispatch(
        updateCurrentListItemDispatcher(
          entityType,
          recordingList[i],
          Operation.Update
        )
      );
    }
    return recordingList;
  };
};

export const updateSegmentDispatcher = (
  contentId,
  text,
  startTime,
  endTime
) => {
  return async (dispatch, getState) => {
    const content = await updateEntityService.updateEntity(
      dispatch,
      getState,
      EntityType.TranscriptContentMeta,
      contentId,
      { text, startTime, endTime },
      "updateSegment"
    );
    dispatch(currentEntityAction(EntityType.Content, content));
    dispatch(
      updateCurrentListItemDispatcher(
        EntityType.Content,
        content,
        Operation.Update
      )
    );
    dispatch(showMessageBarAction(true, "Transcript updated"));
    return content;
  };
};

export const updateChapterListDispatcher = (contentId, chapterList) => {
  return async (dispatch, getState) => {
    const content = await updateEntityService.updateEntityWithJson(
      dispatch,
      getState,
      EntityType.ChapterContentMeta,
      contentId,
      chapterList,
      "updateChapters"
    );
    dispatch(currentEntityAction(EntityType.Content, content));
    dispatch(
      updateCurrentListItemDispatcher(
        EntityType.Content,
        content,
        Operation.Update
      )
    );
    dispatch(showMessageBarAction(true, "Chapter list updated"));
    return content;
  };
};

export const updateCommentDispatcher = (commentId, message, playtime) => {
  return async (dispatch, getState) => {
    const { isLoggedIn } = getState().login;
    const { account } = getState().current.entity;
    const requireLogin = !!account ? account.requireLogin : true;
    if (!isLoggedIn && requireLogin) {
      dispatch(showCreateLoginModalDispatcher());
      dispatch(showMessageBarAction(true, "login to comment"));
      throw new Error("Not signed in");
    }
    const formObject = {
      message,
      playtime,
    };
    const comment = await updateEntityService.updateEntity(
      dispatch,
      getState,
      EntityType.Comment,
      commentId,
      formObject
    );
    dispatch(showErrorMessageBarAction(false));
    dispatch(
      updateCurrentListItemDispatcher(
        EntityType.Comment,
        comment,
        Operation.Update
      )
    );
    dispatch(showMessageBarAction(true, "comment updated!"));
    trackEvent("commentUpdate", { commentId });
    trackIntercomEvent("update comment", { commentId, message });
    return comment;
  };
};

export const updateBotSessionDispatcher = (botSessionId, botSessionStatus) => {
  return async (dispatch, getState) => {
    const formObject = {
      botSessionStatus,
    };
    const post = await updateEntityService.updateEntity(
      dispatch,
      getState,
      EntityType.BotSession,
      botSessionId,
      formObject
    );
    dispatch(showErrorMessageBarAction(false));
    dispatch(
      updateCurrentListItemDispatcher(
        EntityType.BotSession,
        post,
        Operation.Update
      )
    );
    dispatch(showMessageBarAction(true, "Scheduled Recording Updated!"));
    trackEvent("meetingUpdate", { botSessionId });
    trackIntercomEvent("update meeting", { botSessionId, botSessionStatus });
    return post;
  };
};

export const updatePostDispatcher = (postId, message, entityTypeName) => {
  return async (dispatch, getState) => {
    const formObject = {
      message,
    };
    const post = await updateEntityService.updateEntity(
      dispatch,
      getState,
      "post",
      postId,
      formObject
    );
    dispatch(showErrorMessageBarAction(false));
    dispatch(
      updateCurrentListItemDispatcher(entityTypeName, post, Operation.Update)
    );
    dispatch(showMessageBarAction(true, "post updated!"));
    trackEvent("postUpdate", { postId });
    trackIntercomEvent("update post", { postId, message });
    return post;
  };
};

export const updateUserOnboardingAction = (stepKey, selectedItems = []) => {
  const formObject = {};
  formObject[stepKey] = selectedItems.toString();
  return async (dispatch, getState) => {
    const entity = await updateEntityService.updateEntity(
      dispatch,
      getState,
      EntityType.UserOnboarding,
      null,
      formObject
    );
    dispatch(currentEntityAction(EntityType.UserOnboarding, entity));
    return entity;
  };
};

export const updateKeywordAction = (contentId, formObject) => {
  return async (dispatch, getState) => {
    const content = await updateEntityService.updateEntity(
      dispatch,
      getState,
      EntityType.EntityContentMeta,
      contentId,
      formObject,
      "updateKeyword"
    );
    dispatch(showMessageBarAction(true, "Keyword update in progress.."));
    return content;
  };
};

export const updatePlaylistContentListOrderingDispatcher = (
  playlistId,
  playlistContentList
) => {
  return async (dispatch, getState) => {
    const playlistContentIdList = playlistContentList.map((pc) => pc.id);
    await updateEntityService.updateEntity(
      dispatch,
      getState,
      EntityType.Playlist,
      playlistId,
      { playlistContentCsv: playlistContentIdList.toString() },
      "updatePlaylistContentOrdering"
    );
  };
};

export const updateBotAction = (entityTypeName, form, message) => {
  return async (dispatch, getState) => {
    const content = await updateEntityService.updateEntity(
      dispatch,
      getState,
      entityTypeName,
      null,
      form
    );
    dispatch(showMessageBarAction(true, message));
    return content;
  };
};
