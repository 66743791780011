import {
  currentEntityAction,
  EntityType,
  currentListAction,
} from "./CurrentActions";
import { getRelativePath, push } from "../../util/Utils";
import { markParentNotificationMessagesReadDispatcher } from "./UpdateEntityActions";
import { createChatConversationDispatcher } from "./CreateEntityActions";
import { ListName } from "../reducer/CurrentListActionReducer";
import { EXTENSION_SIGN_IN_PATH } from "../../service/ChromeExtensionService";

export const ModalName = {
  AcceptAccessRequestModal: "AcceptAccessRequestModal",
  AcceptShareRequestModal: "AcceptShareRequestModal",
  CreatePlaylistContentModal: "CreatePlaylistContentModal",
  AnalyticsModal: "AnalyticsModal",
  BotSessionsModal: "BotSessionsModal",
  ChannelThumbnailImageUploadModal: "ChannelThumbnailImageUploadModal",
  ChannelSplashImageUploadModal: "ChannelSplashImageUploadModal",
  ConfluenceSummaryOauthModal: "ConfluenceSummaryOauthModal",
  CreateAccountModal: "CreateAccountModal",
  CreateLoginModal: "/createLogin",
  CreateChannelModal: "CreateChannelModal",
  CreatePlaylistModal: "CreatePlaylistModal",
  DownloadModal: "DownloadModal",
  EditAccountModal: "EditAccountModal",
  EditChannelModal: "EditChannelModal",
  EditChaptersModal: "EditChaptersModal",
  EditContentModal: "EditContentModal",
  EditPlaylistModal: "EditPlaylistModal",
  GettingStartedModal: "/gettingStarted",
  GoogleDriveUploadModal: "GoogleDriveUploadModal",
  ExtensionLoginModal: EXTENSION_SIGN_IN_PATH,
  FileUploadModal: "FileUploadModal",
  ForgotPasswordModal: "/forgotPassword",
  GoogleMeetBotOauthModal: "GoogleMeetBotOauthModal",
  LoginModal: "/login",
  ManageChannelShareUsersModal: "ManageChannelShareUsersModal",
  ManageContentShareUsersModal: "ManageContentShareUsersModal",
  ManagePlaylistShareUsersModal: "ManagePlaylistShareUsersModal",
  ManageShareUsersModal: "ManageShareUsersModal",
  OauthSignInModal: "OauthSignInModal",
  OauthSignOutModal: "/oauthSignOut",
  OnboardingModal: "/onboarding",
  PlayerModal: "PlayerModal",
  ResendAccountInviteModal: "ResendAccountInviteModal",
  RecorderUploadModal: "/upload",
  ResetPasswordModal: "ResetPasswordModal",
  RequestAccessModal: "/requestAccess",
  RequestShareModal: "RequestShareModal",
  SlackAccountUserOauthModal: "SlackAccountUserOauthModal",
  SlackChannelOauthModal: "SlackChannelOauthModal",
  SlackKeywordAlertsOauthModal: "SlackKeywordAlertsOauthModal",
  SwitchChannelAccountModal: "SwitchChannelAccountModal",
  SwitchContentAccountModal: "SwitchContentAccountModal",
  SSOLoginModal: "SSOLoginModal",
  TeamsBotOauthModal: "TeamsBotOauthModal",
  TrimVideoModal: "/trim",
  OauthLoginModal: "/oauthLogin",
  UpdatePasswordModal: "UpdatePasswordModal",
  UpgradeAccountModal: "UpgradeAccountModal",
  UserProfileSplashImageUploadModal: "UserProfileSplashImageUploadModal",
  VerifyEmailPinModal: "VerifyEmailPinModal",
  VerifyEmailLinkModal: "VerifyEmailLinkModal",
  VerifyMobileModal: "VerifyMobileModal",
  ViewWatchHistoryModal: "ViewWatchHistoryModal",
  ZoomBotOauthModal: "ZoomBotOauthModal",
  ZoomLinkOauthModal: "ZoomLinkOauthModal",
  RecordingImportModal: "RecordingImportModal",
  RecordingImportOauthModal: "RecordingImportOauthModal",
};

export const DialogName = {
  EditContentKeywordDialog: "EditContentKeywordDialog",
  QuickDeleteDialog: "QuickDeleteDialog",
  QuickEmbedDialog: "QuickEmbedDialog",
  QuickShareDialog: "QuickShareDialog",
};

export const UIComponent = {
  AccountUserFollowerGridSearchBar: "AccountUserFollowerGridSearchBar",
  CameraRecorderDrawer: "CameraRecorderDrawer",
  ChannelContentSearchGridSearchBar: "ChannelContentSearchGridSearchBar",
  ChannelSearchGridSearchBar: "ChannelSearchGridSearchBar",
  ChannelSubscriptionsGridSearchBar: "ChannelSubscriptionsGridSearchBar",
  ChatDrawer: "ChatDrawer",
  Confetti: "Confetti",
  Countdown: "Countdown",
  HomepageAccordionPanel: "HomepageAccordionPanel",
  RecorderBackgroundChooser: "RecorderBackgroundChooser",
  RecorderControls: "RecorderControls",
  RecorderDrawer: "RecorderDrawer",
  RecorderOptions: "RecorderOptions",
  PlaylistSearchGridSearchBar: "PlaylistSearchGridSearchBar",
  TranscriptFilterBar: "TranscriptFilterBar",
  TranscriptSearchBar: "TranscriptSearchBar",
};

export const ScriptName = {
  googleApi: "googleApi",
  googleAccountsApi: "googleAccountsApi",
  videojs: "videojs",
  videojsHotkeys: "videojs-hotkeys",
  nuevo: "nuevo",
  upnext: "upnext",
  offset: "offset",
};

export const UiMode = {
  autoplay: "autoplay",
  galleryMode: "galleryMode",
  playlistMode: "playlistMode",
  playerOnly: "playerOnly",
  chromeless: "chromeless",
  hideTranscript: "hideTranscript",
  hideTopics: "hideTopics",
  hideChapters: "hideChapters",
  hideComments: "hideComments",
  hideSearch: "hideSearch",
};

export const getIsChromeless = (query) => {
  const { chromeless, playerOnly, galleryMode, playlistMode } = query;
  return (
    chromeless === "" ||
    chromeless === "true" ||
    playerOnly === "" ||
    playerOnly === "true" ||
    galleryMode === "" ||
    galleryMode === "true" ||
    playlistMode === "" ||
    playlistMode === "true"
  );
};

export const setUILocalStorage = (prop, value) => {
  return {
    type: "setUILocalStorage",
    prop,
    value,
  };
};

/**
 * This logic is required because NextJS <Script/> onLoad and onReady
 * don't work:
 * @returns
 */
export const setScriptLoadedAction = (scriptName) => {
  return {
    type: "scriptLoaded",
    scriptName,
  };
};

export const setUiModeAction = (uiMode) => {
  return {
    type: "uiMode",
    value: uiMode,
  };
};

export const showLeftDrawerAction = (open) => {
  return {
    type: "showLeftDrawer",
    open,
  };
};

export const showMessageBarAction = (open, message, callback, actionLabel) => {
  return {
    type: "messageBar",
    open,
    message: !message ? "" : message.replace(/([a-z])([A-Z])/g, "$1 $2"),
    callback,
    actionLabel,
  };
};

export const showErrorMessageBarAction = (
  open,
  message,
  callback,
  actionLabel
) => {
  return {
    type: "errorMessageBar",
    open,
    message: !message ? "" : message.replace(/([a-z])([A-Z])/g, "$1 $2"),
    callback,
    actionLabel,
  };
};

export const addToasterMessageAction = (notificationMessage) => {
  return {
    type: "addToasterMessage",
    notificationMessage,
  };
};

export const removeToasterMessageAction = (id) => {
  return {
    type: "removeToasterMessage",
    id,
  };
};

export const showModalAction = (modalName, open, modalTab, modalProps) => {
  return {
    type: modalName,
    open,
    modalTab,
    modalProps,
  };
};

export const showDialogAction = (dialogName, open, anchorEl, props) => {
  return {
    type: "showDialogAction",
    dialogName,
    open,
    anchorEl,
    props,
  };
};

export const showUIComponentAction = (uiComponent, open) => {
  return {
    type: "showUIComponent",
    uiComponent,
    open,
  };
};

export const isBlankPageAction = (isBlankPage) => {
  return {
    type: "isBlankPage",
    isBlankPage,
  };
};

export const incrementBrowserHistoryDispatcher = () => {
  return (dispatch, getState) => {
    let state = getState();
    if (
      !state.login.isLoggedIn &&
      state.ui.browserHistoryCount === 5 &&
      !state.ui.declinedToCreateLogin
    )
      dispatch(showCreateLoginModalDispatcher());
    dispatch(incrementBrowserHistoryAction());
  };
};

export const incrementBrowserHistoryAction = () => {
  return {
    type: "incrementBrowserHistoryCount",
  };
};

export const showAccessRequestModalDispatcher = (
  entityTypeName,
  entityId,
  shareCodes
) => {
  return (dispatch, getState) => {
    const userSession = getState().current.entity.userSession;
    if (userSession) {
      const accessRequest = Object.assign(
        {
          entityTypeName: entityTypeName ? entityTypeName : EntityType.Account,
          entityId: entityId ? entityId : userSession.accountId,
        },
        shareCodes
      );
      dispatch(currentEntityAction(EntityType.AccessRequest, accessRequest));
      dispatch(showModalAction(ModalName.RequestAccessModal, true));
    }
  };
};

export const searchStringAction = (searchString, listName) => {
  return {
    type: "searchString",
    searchString,
    listName,
  };
};

export const resetUIStateAction = () => {
  return {
    type: "RESET_UI_STATE_ACTION",
  };
};

export const sortFieldAction = (entityTypeName, sortField) => {
  return {
    type: "sortField",
    entityTypeName,
    sortField,
  };
};

export const showCreateLoginModalDispatcher = () => {
  return (dispatch, getState) => {
    dispatch(showModalAction(ModalName.CreateLoginModal, true, "register"));
  };
};

export const openNotificationMessageLinkDispatcher = (notificationMessage) => {
  return (dispatch, getState) => {
    dispatch(
      markParentNotificationMessagesReadDispatcher(notificationMessage.id)
    );
    dispatch(removeToasterMessageAction(notificationMessage.id));
    const entity = notificationMessage.entity;
    const entityTypeName = EntityType[notificationMessage.entityType];
    switch (entityTypeName) {
      case EntityType.ChatMessage:
        dispatch(openUserChatDispatcher(entity.fromUser.id));
        break;
      default:
        window.router.navigate(getRelativePath(notificationMessage.entityUrl));
        break;
    }
  };
};

export const openUserChatDispatcher = (userId) => {
  return async (dispatch, getState) => {
    dispatch(currentListAction(ListName.chatMessages, []));
    const chatConversation = await dispatch(
      createChatConversationDispatcher({
        userIdList: [userId],
      })
    );
    push(`/chat/${chatConversation.id}`);
  };
};

export const setDeviceAPIAction = (deviceAPI) => {
  return {
    type: "setDeviceAPI",
    deviceAPI,
  };
};
